<template>
	<div class="field" :class="{disabled:disable}">
		<label v-if="label && name" :for="name">{{ label }}:</label>
		<label v-if="pre" class="pre">{{ pre }}</label>
		<TextInput v-if="name" type="text" :id="name" :placeholder="prompt" :value="validated" @input="onchange" :class="[align]" :style="style" :disabled="disable"></TextInput>
		<TextInput v-if="!name" type="text" :placeholder="prompt" :value="validated" @input="onchange" :class="['align-'+align]" :style="style" :disabled="disable"></TextInput>
		<label v-if="post" class="post">{{ post }}</label>
	</div>
</template>

<script>
import TextInput from "@/components/form/TextInput";
export default {
	name: "TextField",
	components: { TextInput },
	props: {
		name: String,
		label: String,
		prompt: String,
		value: [ String, Number, Date ],
		disable: Boolean,
		trim: Boolean,
		format: String,
		pattern: RegExp,
		align: {
			type: String,
			default: "left",
		},
		pre: String,
		post: String,
		size: String,
		case: String,
	},
	data() {
		return {
			current: this.value,
		};
	},
	computed: {
		style() {
			return this.size ? { width: this.size } : null;
		},
		validated() {
		  return this.validate( this.value );
		},
	},
	methods: {
		validate( value ) {
			let filtered = value == null ? "" : String( value );

			if ( this.trim ) {
				filtered = filtered.trim();
			}

			switch ( this.case ) {
				case "upper" :
					filtered = filtered.toUpperCase();
					break;

				case "lower" :
					filtered = filtered.toLowerCase();
					break;
			}

			switch ( String( this.format ).toLowerCase() ) {
				case "numeric" :
					filtered = filtered.replace( /\D/g, "" );
					break;

				case "alpha" :
					filtered = filtered.replace( /\W/g, "" );
					break;
			}

			if ( this.regexp ) {
				filtered = filtered.match( this.regexp );
				if ( Array.isArray( filtered ) ) {
					filtered = filtered.join( "" );
				}
			}

			return filtered;
		},
		onchange( value ) {
			this.$emit( "input", value );
		},
	},
};
</script>

<style scoped lang="scss">
	.field {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: baseline;

		&.disabled {
			visibility: hidden;
		}
	}

	input {
		flex: 1000 1 auto;

		&.align-right {
			text-align: right;
		}
	}

	.pre {
		flex: 1 1 auto;
		padding-right: 0.3rem;
		text-align: right;
	}

	.post {
		flex: 1 1 auto;
		padding-left: 0.3rem;
	}
</style>
