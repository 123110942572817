<template>
	<div class="field" :class="{disabled:disable}">
		<Selector :placeholder="label" :options="options"
				  :value="String( current || '' )" @input="pick"
				  :disabled="disable" :size="size"></Selector>
	</div>
</template>

<script>
import Selector from "@/components/form/Selector";

export default {
	name: "Picker",
	components: { Selector },
	props: {
		label: String,
		value: [ String, Number ],
		disable: Boolean,
		prompt: String,
		size: String,
		options: {
			type: Array,
			validator( value ) {
				return value.every( item => item.hasOwnProperty( "value" ) && item.label );
			},
		},
	},
	data() {
		return {
			current: this.value,
		};
	},
	methods: {
		pick( value ) {
			this.current = value;

			this.$emit( "input", value );
		},
	},
};
</script>

<style scoped lang="scss">
.field {
	position: relative;
	display: block;

	&.disabled {
		visibility: hidden;
	}
}
</style>
