<template>
	<div class="field">
		<label v-if="label" :for="name">{{ label }}:</label>
		<textarea ref="textarea" :id="name" :rows="countedRows"
				  :class="[`rows-${countedRows}`]" :style="isScrollable?{background:'green'}:undefined" :placeholder="prompt"
				  :value="limitedValue" @input="onchange"></textarea>
	</div>
</template>

<script>
export default {
	name: "TextBlock",
	props: {
		name: String,
		label: String,
		prompt: String,
		value: [ String, Number ],
		trim: Boolean,
		maxRows: Number,
		minRows: Number,
	},
	data() {
		return {};
	},
	computed: {
		isScrollable() {
			return this.$refs.scrollHeight > this.$refs.offsetHeight;
		},
		filteredValue() {
			let filtered = this.value == null ? "" : String( this.value );

			if ( this.trim ) {
				filtered = filtered.trim();
			}

			return filtered;
		},
		countedRows() {
			const filtered = this.filteredValue;
			const rows = filtered.trim().split( /\r?\n/ );

			return Math.max( this.minRows || 1, Math.min( rows.length, this.maxRows || Infinity ) );
		},
		limitedValue() {
			const filtered = this.filteredValue;
			const rows = this.countedRows;

			if ( rows.length > this.maxRows ) {
				return rows.slice( 0, this.maxRows ).join( "\n" );
			}

			return filtered;
		},
	},
	methods: {
		onchange( event ) {
			this.$emit( "input", event.target.value );
		},
	},
};
</script>

<style scoped lang="scss">
.field {
	display: flex;
	flex-flow: row nowrap;
	align-items: baseline;
	justify-content: stretch;
	position: relative;
}

textarea {
	--vertical-padding: calc( 0.2rem + ( ( var(--form-line-height) - var(--line-height) ) / 2 ) );

	flex: 100 1 auto;
	resize: vertical;
	display: block;
	background: var(--touch-background-active);
	padding: var(--vertical-padding) 0.5rem;
	border: 0;
	border-radius: 0;
	font: inherit;
	line-height: var(--line-height);
	outline: 0;
	margin: 0;
	box-shadow: none;
	appearance: none;
	color: var(--touch-text-active);
	box-sizing: content-box;

	&:not(:disabled) {
		cursor: text;
	}

	&:disabled {
		background: var(--touch-background-inactive);
		color: var(--touch-text-inactive);
	}

	&:focus {
		background: var(--touch-background-focused);
		padding-bottom: calc( var(--vertical-padding) - var(--touch-frame-width) );
		border-bottom: var(--touch-frame-width) solid var(--brand-color);
		outline: 0;
	}

	&::placeholder {
		color: var(--touch-text-inactive);
	}
}

@for $rows from 1 through 10 {
	textarea.rows-#{$rows} {
		height: calc( ( #{$rows} * var(--line-height) ) );
	}
}
</style>
