<template>
	<button class="button" :class="{selected, flat}" :type="submit ? 'submit' : undefined" :disabled="disabled" @click="$emit( 'click', $event )">
		<span><slot></slot></span>
	</button>
</template>

<script>
export default {
	name: "Button",
	props: {
		submit: Boolean,
		disabled: Boolean,
		selected: Boolean,
		flat: Boolean,
	},
};
</script>

<style scoped lang="scss">
button {
	font: inherit;
	display: block;
	line-height: 2rem;
	outline: 0;
	margin: 0;
	padding: 0;
	appearance: none;
	box-sizing: border-box;
	background: var(--touch-background-active);
	color: var(--brand-color);

	&.flat {
		padding: 0.2rem 0.5rem;
		border: 0;
	}

	&:not(.flat) {
		padding: calc( 0.2rem - var(--touch-frame-width) ) 0.5rem;
		border: var(--touch-frame-width) solid var(--brand-color);
	}

	> span {
		display: inline-block;
		margin: auto;
	}

	&:not(:disabled) {
		&:hover {
			background: var(--brand-color);
			color: var(--dark-text-color);
			cursor: pointer;
		}

		&:focus:not(:hover) {
			background: var(--touch-background-focused);
		}

		label {
			cursor: pointer;
		}
	}

	&:disabled {
		opacity: 0.3;
	}

	&.selected, &.selected:focus:not(:hover) {
		background: var(--brand-color);
		color: var(--dark-text-color);
	}
}
</style>
