<template>
	<button v-if="faIcon" class="action icon" :class="{dark}" :disabled="disabled" @click="action"><fa :icon="faIcon"></fa></button>
	<button v-else class="action text" :class="{dark}" :disabled="disabled" @click="action"><slot>{{ label }}</slot></button>
</template>

<script>
export default {
	name: "ActionButton",
	props: {
		label: String,
		icon: String,
		disabled: Boolean,
		dark: Boolean,
	},
	methods: {
		action() {
			this.$emit( "action" );
		},
	},
	computed: {
		faIcon() {
			const match = /^(?:(fa[sdlbr])-)?(\S+)$/.exec( this.icon || "" );
			if ( match ) {
				return [ match[1] || "fad", match[2] ];
			}

			return null;
		},
	},
};
</script>

<style scoped lang="scss">
	.action {
		appearance: none;
		font: inherit;
		line-height: inherit;
		background: var(--bright-icon-background);
		color: var(--bright-icon-text);
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		&.dark {
			background: var(--dark-icon-background);
			color: var(--dark-icon-text);
		}

		&:not(:disabled) {
			cursor: pointer;

			&:hover {
				background: var(--bright-icon-focused);

				&.dark {
					background: var(--dark-icon-focused);
				}
			}
		}

		&.icon {
			border: none;
			min-width: 2em;
		}

		&.text {
			padding-left: 0.7em;
			padding-right: 0.7em;
		}

		&:disabled {
			opacity: 0.3;
		}
	}

	@media screen and (max-width: 639.99px) {
		.toggle:disabled {
			display: none;
		}
	}
</style>
