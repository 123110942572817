import { render, staticRenderFns } from "./PdfPreview.vue?vue&type=template&id=1d22fccd&scoped=true&"
import script from "./PdfPreview.vue?vue&type=script&lang=js&"
export * from "./PdfPreview.vue?vue&type=script&lang=js&"
import style0 from "./PdfPreview.vue?vue&type=style&index=0&id=1d22fccd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d22fccd",
  null
  
)

export default component.exports