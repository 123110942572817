<template>
	<div class="container">
		<div class="header" v-if="false">
			<div class="row">
				<div class="position">#</div>
				<div class="description">Beschreibung</div>
				<div class="amount">Menge</div>
				<div class="price">Einzelpreis</div>
				<div class="tax">Steueranteil</div>
				<div class="tools">
					<ActionButton label="+" @action="addItem(-1)"/>
				</div>
			</div>
		</div>
		<div class="body">
			<div class="row" v-for="( item, index ) of items" :key="item.key"
				 @keydown.exact.shift.ctrl.n.capture.prevent="addItem(index)"
				 @keydown.exact.shift.ctrl.up.capture.prevent="moveUp(index)"
				 @keydown.exact.shift.ctrl.down.capture.prevent="moveDown(index)"
			>
				<div class="description">
					<TextBlock prompt="Beschreibung" :value="item.description" :min-rows="2"
							   @input="setCell(index, 'description', $event)"></TextBlock>
				</div>
				<div class="amount">
					<TextInput prompt="Menge" :value="item.amount" align="right"
							   post="x" size="2.2rem"
							   :disable="item.unit===''||item.unit==='-'"
							   @input="setCell(index, 'amount', $event)"/>
				</div>
				<div class="unit">
					<Picker :value="item.unit"
							@input="setCell(index,'unit', $event)"
							:options="units" prompt="Einheit"/>
				</div>
				<div class="price">
					<TextInput prompt="Einzelpreis" :value="item.price"
							   align="right" pre="á" post="€" size="3rem"
							   :disable="item.unit===''"
							   @input="setCell(index, 'price', $event)"/>
				</div>
				<div class="discount">
					<TextInput prompt="Rabatt" :value="item.discount"
							   align="right" pre="-" post="%" size="2rem"
							   :disable="item.unit===''"
							   @input="setCell(index, 'discount', $event)"/>
				</div>
				<div class="tax">
					<Picker :value="item.tax"
							@input="setCell(index,'tax', $event)"
							:options="taxes" prompt="Steuer" size="7rem"
							:disable="item.unit===''"/>
				</div>
				<div class="tools"><span>
					<Button flat title="Insert another item below this one ..."
							@click="addItem(index)"><fa :icon="['fas', 'plus']"></fa></Button>
					<Button flat title="Move item towards top of list ..."
							@click="moveUp(index)" :disabled="index === 0"><fa :icon="['fas', 'arrow-alt-up']"></fa></Button>
					<Button flat title="Move item towards bottom of list ..."
							@click="moveDown(index)" :disabled="index === items.length - 1"><fa :icon="['fas', 'arrow-alt-down']"></fa></Button>
					<Button flat title="Remove this item from list ..."
							@click="removeItem(index)"><fa :icon="['fas', 'trash-alt']"></fa></Button>
				</span></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "./TextField";
import TextBlock from "./TextBlock";
import ActionButton from "./ActionButton";
import Picker from "./Picker";
import Button from "@/components/form/Button";

export default {
	name: "ItemsEditor",
	components: { Button, Picker, ActionButton, TextBlock, TextInput },
	data() {
		return {
			units: [
				{ value: "", label: "Info" },
				{ value: "-", label: "pauschal" },
				{ value: "pc", label: "Stk." },
				{ value: "e", label: "Einh." },
				{ value: "h", label: "Std." },
				{ value: "m", label: "Monat" },
				{ value: "y", label: "Jahr" },
			],
			taxes: [
				{ value: 19, label: "zzgl. 19% MwSt." },
				{ value: 16, label: "zzgl. 16% MwSt." },
				{ value: 7, label: "zzgl. 7% MwSt." },
				{ value: 5, label: "zzgl. 5% MwSt." },
				{ value: -19, label: "inkl. 19% MwSt." },
				{ value: -16, label: "inkl. 16% MwSt." },
				{ value: -7, label: "inkl. 7% MwSt." },
				{ value: -5, label: "inkl. 5% MwSt." },
				{ value: 0, label: "ohne MwSt." },
			],
		};
	},
	computed: {
		...mapState( {
			items: state => state.invoice.items,
		} ),
	},
	methods: {
		setCell( index, property, value ) {
			this.$store.dispatch( "invoice/setItemProperty", {
				index,
				property,
				value
			} );
			this.$emit( "input", this.items );
		},
		addItem( index ) {
			const item = this.items[index] || {};

			this.$store.dispatch( "invoice/addItem", {
				index: index + 1,
				tax: item.tax,
				unit: item.unit,
			} );
			this.$emit( "input", this.items );
		},
		removeItem( index ) {
			if ( confirm( `Soll der Eintrag #${index + 1} wirklich gelöscht werden?` ) ) {
				this.$store.dispatch( "invoice/removeItem", index );

				if ( this.$store.state.invoice.items.length < 1 ) {
					this.$store.dispatch( "invoice/addItem" );
				}

				this.$emit( "input", this.items );
			}
		},
		moveUp( index ) {
			this.$store.dispatch( "invoice/moveItem", {
				index,
				delta: -1
			} );
			this.$emit( "input", this.items );
		},
		moveDown( index ) {
			this.$store.dispatch( "invoice/moveItem", {
				index,
				delta: +1
			} );
			this.$emit( "input", this.items );
		},
	},
	created() {
		if ( this.$store.state.invoice.items.length < 1 ) {
			this.$store.dispatch( "invoice/addItem" );
		}
	}
};
</script>

<style scoped lang="scss">
.container {
	overflow: auto;

	> .body {
		width: 100%;
	}

	.row {
		&:nth-child(2n+1) {
			background: var(--row-odd-background);
		}

		&:nth-child(2n) {
			background: var(--row-even-background);
		}

		> div {
			font: inherit;
			line-height: 2rem;
		}

		> .tools {
			> span {
				display: inline-flex;
				flex-flow: row nowrap;
				justify-content: flex-end;
				align-items: center;
			}
		}
	}
}

@media print, screen and (min-width: 1024px) {
	.container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		> .body {
			display: table;
		}

		.row {
			display: table-row;
			vertical-align: top;
			padding: 0.5rem 1em;

			> div {
				display: table-cell;
				vertical-align: top;
				padding: 0.5rem 0.2rem;
				border-bottom: 1px solid var(--row-separator-color);

				&:first-child {
					padding-left: 1rem;
				}

				&:last-child {
					padding-right: 1rem;
				}
			}

			> .amount {
				width: 1px;
			}

			> .price {
				width: 1px;
			}

			> .unit {
				width: 1px;
			}

			> .discount {
				width: 1px;
			}

			> .tax {
				width: 1px;
			}

			> .tools {
				width: 1px;
			}
		}
	}
}

@media screen and (max-width: 1023.99px) {
	.row {
		display: grid;
		grid-template-rows: auto 1fr;
		grid-gap: 0.5rem;
		vertical-align: top;
		padding: 1rem;
		border-bottom: 1px solid var(--row-separator-color);

		> div {
			vertical-align: top;
		}

		> .description {
			grid-area: description;
		}

		> .amount {
			grid-area: amount;
		}

		> .price {
			grid-area: price;
		}

		> .unit {
			grid-area: unit;
		}

		> .discount {
			grid-area: discount;
		}

		> .tax {
			grid-area: tax;
		}

		> .tools {
			grid-area: tools;
			text-align: right;

			> span > button {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}
}

@media screen and (max-width: 1023.99px) and (min-width: 640px) {
	.row {
		grid-template-areas: "description description description tools tools" "amount unit price discount tax";
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 639.99px) {
	.row {
		grid-template-areas: "description description description" "amount unit price" "discount tax tax" "tools tools tools";
		grid-template-columns: 1fr 1fr 1fr;
	}
}
</style>
