<template>
	<object class="preview" type="application/pdf" :data="$store.state.pdf.url">
		<p>This browser does not support PDF preview.</p>
	</object>
</template>

<script>
export default {
	name: "PdfPreview",
};
</script>

<style scoped lang="scss">
	.preview {
		width: 100%;
		height: 100%;
	}
</style>
