<template>
	<div class="setup">
		<div class="tabs">
			<Button flat @click="show('context')"
					:selected="panel === 'context'">Kontext
			</Button>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<Button flat @click="show('table')"
					:selected="panel === 'table'">Postenliste
			</Button>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<Button flat @click="show('summary')"
					:selected="panel === 'summary'">Zusammenfassung
			</Button>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<Button flat @click="show('document')"
					:selected="panel === 'document'">Dokument
			</Button>
		</div>
		<div class="panels">
			<CodeEditor ref="context" v-show="panel === 'context'" v-model="contextCode"></CodeEditor>
			<CodeEditor ref="table" v-show="panel === 'table'" v-model="tableCode"></CodeEditor>
			<CodeEditor ref="summary" v-show="panel === 'summary'" v-model="summaryCode"></CodeEditor>
			<CodeEditor ref="document" v-show="panel === 'document'" v-model="documentCode"></CodeEditor>
		</div>
		<div class="control">
			<Button flat @click="help = 'security'" :selected="true">Sicherheitshinweise</Button>
			<Button flat @click="help = 'reference'" :selected="help === 'reference'">Hilfe</Button>
			<Button flat @click="resetSetup">Alle zurücksetzen</Button>
		</div>
		<div class="help" v-if="help">
			<div class="content" v-html="helpContent"></div>
			<div class="closer">
				<Button flat @click="help = null"><fa :icon="['fas', 'times']"></fa></Button>
			</div>
		</div>
	</div>
</template>

<script>
import Button from "@/components/form/Button";
import Security from "../assets/help/security.shtml";
import Reference from "../assets/help/reference.shtml";

export default {
	name: "Setup",
	components: {
		CodeEditor: () => import( /* webpackChunkName: "setup" */ "@/components/form/CodeEditor" ),
		Button,
	},
	data() {
		return {
			panel: "context",
			help: null,
		};
	},
	computed: {
		contextCode: {
			get() { return this.$store.state.setup.context; },
			set( value ) { return this.$store.commit( "setup/setContext", value ); },
		},
		tableCode: {
			get() { return this.$store.state.setup.table; },
			set( value ) { return this.$store.commit( "setup/setTable", value ); },
		},
		summaryCode: {
			get() { return this.$store.state.setup.summary; },
			set( value ) { return this.$store.commit( "setup/setSummary", value ); },
		},
		documentCode: {
			get() { return this.$store.state.setup.document; },
			set( value ) { return this.$store.commit( "setup/setDocument", value ); },
		},
		helpContent() {
			const trimmer = /^[\s\S]*?<body[^>]*>|<\/body\b[\s\S]+$|<(?:script|link|object|iframe)\b[^>]*>/gi;

			switch ( this.help ) {
				case "security" :
					return Security.replace( trimmer, "" );
				case "reference" :
					return Reference.replace( trimmer, "" );

				default : return "";
			}
		},
	},
	methods: {
		resetSetup() {
			if ( confirm( "Sollen wirklich alle Ihre Änderungen verworfen und die Werkseinstellungen wiederhergestellt werden?" ) ) {
				this.$store.commit( "setup/reset" );
			}
		},
		show( name ) {
			if ( this.$refs[name] ) {
				this.panel = name;
				setTimeout( () => {
					this.$refs[name].focus();
				}, 50 );
			}
		},
	},
};
</script>

<style scoped lang="scss">
.setup {
	display: flex;
	height: 100%;
	flex-flow: column nowrap;
	justify-content: stretch;
	align-items: stretch;

	> .tabs {
		flex: 0 0 var(--form-line-height);
		display: flex;
		flex-flow: row;
		justify-content: flex-start;
		align-items: stretch;
		padding-top: 0.5rem;
		background: var(--header-background);
		padding-left: 1rem;

		> button {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		> span {
			padding: 0 0.5rem;
			opacity: 0.4;
			line-height: var(--form-line-height);
			position: relative;
			top: 0.2rem;
			color: var(--dark-text-color);
		}
	}

	> .panels {
		flex: 1000 1 auto;
		position: relative;

		> * {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	> .control {
		flex: 0 0 var(--form-line-height);
		background: var(--header-background);
		display: flex;
		flex-flow: row;
		justify-content: flex-end;
		align-items: stretch;
		padding: 0.5rem 0;

		> * {
			margin-right: 1rem;
		}
	}

	> .help {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50%;
		background: white;
		z-index: 100000;

		> .closer {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100000;

			> button {
				min-width: 3rem;
			}
		}

		> .content {
			padding: 1rem;
			overflow: auto;
			background: var(--touch-background-inactive);
			color: var(--bright-text-color);
			border-left: 1px solid var(--touch-background-focused);
			line-height: 1.681;
			height: 100%;
			box-sizing: border-box;
		}
	}
}
</style>

<style lang="scss">
.setup > .tabs > button {
	opacity: 0.8;

	&:not(:disabled) {
		&.selected, &.selected:hover {
			background: white;
			color: var(--brand-color);
			opacity: 1;
		}
	}
}

.setup > .help > .content {
	hyphens: auto;

	blockquote.danger {
		margin: 1em 0;
		padding: 1em;
		background: #dd0000;
		color: white;
	}

	li {
		margin-top: 0.5em;
	}

	a {
		color: var(--brand-color);
	}

	dt {
		font-weight: bold;
	}
}
</style>
