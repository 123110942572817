<template>
	<div id="app" @keydown.ctrl.exact.capture="shortcut">
		<div id="panel" @keypress.exact="search">
			<div class="info">
				<span title="ID of latest invoice in storage">{{latestInvoiceId}}</span>
			</div>
			<div class="storage">
				<div class="group selectors">
					<select ref="invoices" v-model="selectedInvoice"
							@keydown.enter.capture="loadInvoice">
						<option value="" disabled class="prompt">Rechnungen:
						</option>
						<option :value="item.invoiceId"
								v-for="item in invoicesOfCustomer"
								:key="item.invoiceId">{{ item.invoiceId }}
						</option>
					</select>
					<select ref="customers" v-model="selectedCustomerInvoice">
						<option value="" disabled class="prompt">Kunden:
						</option>
						<option :value="item.invoiceId"
								v-for="item in customers"
								:key="item.invoiceId">
							{{ item.customerId }}
							({{ item.recipient.replace( /\n[\s\S]+$/, "" ) }})
						</option>
					</select>
				</div>
				<div class="group">
					<ActionButton dark icon="fad-folder-open"
								  @action="loadInvoice"
								  :title="hasSelection ? 'Load invoice from storage ...' : 'Select invoice first!'"
								  :disabled="!hasSelection"></ActionButton>
					<ActionButton dark icon="fad-save" @action="saveInvoice"
								  :title="hasStorage ? hasInvoiceId ? 'Save invoice in storage ...' : 'Missing invoice ID!' : 'Missing local storage!'"
								  :disabled="!hasStorage || !hasInvoiceId"></ActionButton>
					<ActionButton dark icon="fad-trash-alt"
								  @action="removeInvoice"
								  :title="hasSelection ? 'Remove invoice from storage ...' : 'Select invoice first!'"
								  :disabled="!hasSelection"></ActionButton>
					<ActionButton dark icon="fad-file-alt"
								  title="Start new invoice ..."
								  @action="reset"></ActionButton>
					<ActionButton dark icon="fad-download"
								  title="Download storage for backup ..."
								  @action="backup"></ActionButton>
					<ActionButton dark icon="fad-upload"
								  title="Restore storage from backup ..."
								  @action="restore"></ActionButton>
					<div class="uploader"><input type="file" accept=".json"
												 ref="uploader"></div>
				</div>
			</div>
			<div class="settings">
				<ToggleButton dark :state="$store.state.setup.visible"
							  icon="fad-drafting-compass"
							  :title="$store.state.setup.visible ? 'Hides document setup editor ...' : 'Opens document setup editor ...'"
							  @toggle="toggleDocumentSetup"></ToggleButton>
				<ToggleButton dark :state="$store.state.pdf.preview"
							  icon="fad-file-pdf"
							  :title="$store.state.pdf.preview ? 'Hide PDF preview ...' : 'Show PDF preview ...'"
							  :disabled="$store.getters['device/isSmallDevice']"
							  @toggle="togglePreview"></ToggleButton>
				<ToggleButton dark :state="$store.state.vertical"
							  icon="fad-border-center-h"
							  :disabled="!$store.state.pdf.preview || $store.getters['device/isLeanDevice']"
							  title="Toggle editor orientation ..."
							  @toggle="toggleOrientation"></ToggleButton>
				<ActionButton dark icon="fad-sync-alt" title="Re-render PDF ..."
							  v-if="!$store.getters['device/isSmallDevice']"
							  :disabled="!$store.state.pdf.preview"
							  @action="rerenderPreview"></ActionButton>
				<ActionButton dark icon="fad-external-link" title="Download PDF ..."
							  @action="downloadPreview"></ActionButton>
				<ActionButton dark icon="fad-bug"
							  title="Request support or report a bug ..."
							  @action="reportIssue"></ActionButton>
			</div>
		</div>
		<router-view ref="content"/>
	</div>
</template>

<script>
import ToggleButton from "./components/ToggleButton";
import ActionButton from "./components/ActionButton";
import { formatDate } from "@/lib/data";

export default {
	data() {
		return {
			selectedInvoice: "",
			selectedCustomerInvoice: "",
		};
	},
	components: { ActionButton, ToggleButton },
	methods: {
		toggleOrientation( vertical ) {
			this.$store.dispatch( "switchOrientation", vertical );
		},
		togglePreview( preview ) {
			this.$store.dispatch( "switchPreview", preview );
		},
		toggleDocumentSetup( show ) {
			this.$store.dispatch( show ? "setup/show" : "setup/hide" );
		},
		rerenderPreview() {
			this.$store.dispatch( "rerenderPdf" );
		},
		downloadPreview() {
			this.$store.dispatch( "downloadPdf" );
		},
		reset() {
			this.$store.dispatch( "invoice/reset" );
		},
		reportIssue() {
			const a = document.createElement( "a" );
			a.href = "mailto:bot+cepharum-accounting-150-issue-@git.cepharum.de?subject=Supportanfrage";
			a.click();
		},
		backup() {
			Promise.all( [
				this.$store.dispatch( "invoice/backup" ),
				this.$store.dispatch( "setup/backup" ),
			] )
				.then( ( [ invoices, setup ] ) => {
					const url = URL.createObjectURL( new Blob(
						[JSON.stringify( { version: 1, invoices, setup } )],
						{ type: "text/json;charset=utf-8" }
					) );

					const anchor = document.createElement( "a" );
					anchor.href = url;
					anchor.target = "_blank";
					anchor.download = `invoices-dump-${formatDate( "%y-%0m-%0d %0h-%0i" )}.json`;

					anchor.click();

					URL.revokeObjectURL( url );
				} )
				.catch( cause => {
					console.error( cause );
					alert( "Backup failed." );
				} );
		},
		restore() {
			this.$refs.uploader.onchange = () => {
				const file = this.$refs.uploader.files[0];

				if ( file ) {
					file.text()
						.then( json => { // eslint-disable-line consistent-return
							let data;

							try {
								data = JSON.parse( json );
							} catch ( e ) {} // eslint-disable-line no-empty

							if ( data && typeof data === "object" ) {
								if ( data.invoices && typeof data.invoices === "object" &&
									 data.setup && typeof data.setup === "object" &&
									 data.version > 0 ) {
									return Promise.all( [
										this.$store.dispatch( "invoice/restore", data.invoices ),
										this.$store.dispatch( "setup/restore", data.setup ),
									] );
								}

								return this.$store.dispatch( "invoice/restore", data );
							}
						} )
						.catch( cause => {
							console.error( cause );
							alert( "Restoring data failed. See JS console for additional information!" );
						} );
				}
			};
			this.$refs.uploader.click();
		},
		saveInvoice() {
			this.$store.dispatch( "invoice/save" );
		},
		loadInvoice() {
			let promise;

			if ( this.selectedInvoice ) {
				promise = this.$store.dispatch( "invoice/load", this.selectedInvoice );
			} else if ( this.selectedCustomerInvoice ) {
				promise = this.$store.dispatch( "invoice/load", this.selectedCustomerInvoice );
			}

			if ( promise ) {
				promise.then( () => this.$refs.content.$el.querySelector( "input, textarea, select" )?.focus() ); // eslint-disable-line promise/catch-or-return
			}

			this.selectedInvoice = this.selectedCustomerInvoice = "";
		},
		removeInvoice() {
			const id = this.selectedInvoice;

			if ( id && confirm( `Soll die Rechnung ${id} wirklich gelöscht werden?` ) ) {
				this.$store.dispatch( "invoice/remove", id ) // eslint-disable-line promise/catch-or-return
					.then( () => this.$refs.content.$el.querySelector( "input, textarea, select" )?.focus() );
			}

			this.selectedInvoice = this.selectedCustomerInvoice = "";
		},
		search( event ) {
			clearTimeout( this._resetTimer );
			this._resetTimer = setTimeout( () => { this._query = ""; }, 2000 );

			const query = this._query = ( ( this._query || "" ) + event.key ).toLowerCase();
			this.selectedInvoice = "";

			let customerCandidate = "";
			const pool = this.invoices;
			const length = pool.length;

			for ( let i = 0; i < length; i++ ) {
				const { invoiceId, customerId, recipient } = pool[i] || {};

				if ( String( invoiceId || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					this.selectedInvoice = invoiceId;
					return;
				}

				if ( String( customerId || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					customerCandidate = invoiceId;
				} else if ( String( recipient || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					customerCandidate = invoiceId;
				}
			}

			if ( customerCandidate ) {
				this.selectedInvoice = customerCandidate;
			}
		},
		shortcut( event ) {
			switch ( String( event.key || "" ).toLowerCase() ) {
				case "f" :
					this.$refs.invoices.focus();
					break;

				case "c" :
					this.$refs.customers.focus();
					break;

				default :
					return;
			}

			event.preventDefault();
			event.stopPropagation();
		},
	},
	computed: {
		hasStorage() {
			return this.$store.getters["invoice/hasStorage"];
		},
		hasInvoiceId() {
			return this.$store.getters["invoice/hasInvoiceId"];
		},
		hasSelection() {
			return this.hasStorage && ( this.selectedCustomerInvoice || this.selectedInvoice );
		},
		latestInvoiceId() {
			return this.$store.getters["invoice/latestInvoiceId"];
		},
		invoices() {
			const invoices = this.$store.state.invoice.saved.slice();

			invoices.sort( ( l, r ) => {
				const lid = String( l.invoiceId || "" ).trim();
				const rid = String( r.invoiceId || "" ).trim();

				return rid.localeCompare( lid );
			} );

			return invoices;
		},
		customers() {
			const customers = this.$store.getters["invoice/latestPerCustomer"].slice();

			customers.sort( ( l, r ) => String( r.invoiceId ).trim().localeCompare( String( l.invoiceId ).trim() ) );

			return customers;
		},
		invoicesOfCustomer() {
			if ( this.selectedCustomerInvoice ) {
				const ref = this.invoices.find( invoice => invoice.invoiceId === this.selectedCustomerInvoice );

				if ( ref ) {
					return this.invoices.filter( invoice => invoice.customerId === ref.customerId );
				}
			}

			return this.invoices;
		},
	},
};
</script>

<style lang="scss">
$brand_color: #c30045;
$dark_text: darken($brand_color, 30%);
$bright_text: white;

:root {
	--font-family: "Open Sans";
	--font-size: 12pt;
	--line-height: 1.36rem;
	--form-line-height: 2rem;

	--brand-color: #{$brand_color};

	--bright-background: white;
	--bright-text-color: #{$dark_text};
	--dark-background: #{darken( $brand_color, 15% )};
	--dark-text-color: #{$bright_text};

	--navbar-bg: var(--dark-background);
	--navbar-text: var(--dark-text-color);

	--touch-frame-width: 0.2rem;
	--touch-background-active: #{desaturate(lighten( $brand_color, 58%), 50%)};
	--touch-background-focused: #{desaturate(lighten( $brand_color, 53%), 60%)};
	--touch-background-inactive: #{lighten( $brand_color, 60%)};
	--touch-text-active: #{$dark_text};
	--touch-text-inactive: #{transparentize( $dark_text, 0.4 )};

	--bright-icon-background: var(--bright-background);
	--bright-icon-text: var(--bright-text-color);
	--bright-icon-focused: var(--touch-background-active);
	--bright-icon-set-background: var(--dark-background);
	--bright-icon-set-text: var(--dark-text-color);
	--bright-icon-set-focused: #{darken( $brand_color, 10% )};

	--dark-icon-background: transparent;
	--dark-icon-text: var(--dark-text-color);
	--dark-icon-focused: #ffffff40;
	--dark-icon-set-background: #{desaturate(lighten( $brand_color, 55%), 60%)};
	--dark-icon-set-text: var(--bright-text-color);
	--dark-icon-set-focused: #{desaturate(lighten( $brand_color, 50%), 60%)};

	--row-separator-color: #{desaturate(lighten( $brand_color, 50%), 70%)};

	--view-background: white;
	--header-background: #{desaturate(lighten( $brand_color, 38%), 70%)};
	--row-even-background: #{desaturate(lighten( $brand_color, 62%), 70%)};
	--row-odd-background: #{desaturate(lighten( $brand_color, 61%), 70%)};
}

html {
	position: relative;
	height: 100%;
	font: normal var(--font-size)/var(--line-height) var(--font-family);
}

body {
	margin: 0;
	padding: 0;
	border: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: stretch;
	align-items: stretch;
	position: relative;
	min-height: 100%;
}

#app {
	flex: 0 0 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: stretch;
	align-items: stretch;
}

#panel {
	flex: 0 0 auto;
	display: grid;
	grid-template-areas: "info storage settings";
	grid-gap: 1rem;
	vertical-align: center;
	background: var(--navbar-bg);
	color: var(--navbar-text);
	padding: 0.3rem 1rem;
	line-height: 2;

	> * {
		display: flex;
		flex-flow: row wrap;
	}

	a {
		color: white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.info {
	grid-area: info;
	justify-content: flex-start;

	> * + * {
		margin-left: 0.5em;
	}
}

.storage {
	grid-area: storage;
	justify-content: center;

	.group {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		white-space: nowrap;

		+ .group, > * + * {
			margin-left: 0.2rem;
		}
	}

	select {
		font: inherit;
		max-width: 10em;
		margin-right: 0.5em;

		option.prompt > span {
			color: #ddd;
		}
	}
}

.settings {
	grid-area: settings;
	justify-content: flex-end;

	> * + * {
		margin-left: 0.2em;
	}
}

.uploader {
	width: 0;
	height: 0;
	overflow: hidden;
}

@media screen and (min-width: 640px) and (min-height:960px) {
	body {
		overflow: hidden;
	}
}

@media screen and (max-width: 1023.99px) {
	#panel {
		grid-template-areas: "info settings" "storage storage";
	}
}

@media screen and (max-width: 639.99px) {
	#panel {
		.storage > .group.selectors {
			white-space: normal;
			text-align: center;

			> * + * {
				margin-left: 0;
			}

			+ .group {
				margin-top: 1rem;
			}
		}

		button {
			min-width: 3rem;
		}
	}
}
</style>
