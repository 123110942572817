<template>
	<input :placeholder="placeholder" :disabled="disabled" ref="input"
		   :value="value" @input="$emit( 'input', $event.target.value )"
		   :type="concealed ? 'password' : type">
</template>

<script>
export default {
	name: "TextInput",
	props: {
		value: String,
		placeholder: String,
		concealed: Boolean,
		disabled: Boolean,
		type: {
			type: String,
			default: "text",
		},
	},
	methods: {
		focus() {
			this.$refs.input.focus();
		},
	},
};
</script>

<style scoped lang="scss">
input {
	display: block;
	appearance: none;
	padding: 0.2rem 0.5rem calc( 0.2rem - var(--touch-frame-width) ) 0.5rem;
	margin: 0;
	outline: 0;
	border: 0;
	border-bottom: var(--touch-frame-width) solid transparent;
	border-radius: 0;
	font: inherit;
	font-size: 1rem;
	line-height: 2rem;
	box-shadow: none;
	background: var(--touch-background-active);
	color: var(--touch-text-active);

	&::placeholder {
		color: var(--touch-text-inactive);
	}

	&:not(:disabled) {
		cursor: text;
	}

	&:disabled {
		background: var(--touch-background-inactive);
		color: var(--touch-text-inactive);
	}

	&:focus {
		background: var(--touch-background-focused);
		border-bottom-color: var(--brand-color);
		outline: 0;
	}
}
</style>
