import Vue from "vue";
import Vuex from "vuex";
import invoice from "./invoice";
import device from "./device";
import setup from "./setup";

Vue.use( Vuex );

export default new Vuex.Store( {
	strict: process.env.NODE_ENV !== "production",
	state: {
		pdf: {
			renderer: null,
			url: null,
			preview: localStorage.pdfPreview == null ? true : Boolean( parseInt( localStorage.pdfPreview ) ),
		},
		vertical: Boolean( parseInt( localStorage.verticalLayout ) ),
	},
	mutations: {
		switchOrientation( state, vertical ) {
			state.vertical = Boolean( vertical );
			localStorage.verticalLayout = vertical ? 1 : 0;
		},
		switchPreview( state, showPreview ) {
			state.pdf.preview = Boolean( showPreview );
			localStorage.pdfPreview = showPreview ? 1 : 0;
		},
		changeRenderer( state, renderer ) {
			if ( typeof renderer === "function" ) {
				state.pdf.renderer = renderer;
			}
		},
		changePdf( state, pdfUrl ) {
			if ( state.pdf.url ) {
				window.URL.revokeObjectURL( state.pdf.url );
			}

			state.pdf.url = pdfUrl;
		},
	},
	actions: {
		switchOrientation( { commit }, vertical ) {
			commit( "switchOrientation", vertical );
		},
		switchPreview( { commit }, show ) {
			commit( "switchPreview", show );
		},
		rerenderPdf( { commit, state } ) {
			if ( typeof state.pdf.renderer === "function" ) {
				return Promise.resolve( state.pdf.renderer( state ) )
					.then( url => {
						commit( "changePdf", url );
					} );
			}

			return Promise.resolve();
		},
		downloadPdf( { state } ) {
			if ( typeof state.pdf.renderer === "function" ) {
				return Promise.resolve( state.pdf.renderer( state ) )
					.then( url => {
						const a = document.createElement( "a" );
						a.download = `${state.invoice.invoiceId || "invoice"}.pdf`;
						a.href = url;

						a.click();

						setTimeout( () => {
							window.URL.revokeObjectURL( url );
						}, 100 );
					} );
			}

			return Promise.resolve();
		},
		renderPdf( { commit, dispatch }, { renderer } ) {
			commit( "changeRenderer", renderer );

			dispatch( "rerenderPdf" );
		},
		updated( { state, dispatch } ) {
			return Promise.resolve( state._deferredProcessor )
				.finally( () => {
					state._deferredProcessor = null;
				} )
				.then( () => {
					clearTimeout( state._delay );
					state._delay = setTimeout( () => {
						state._deferredProcessor = dispatch( "rerenderPdf" );
					}, 500 );
				} );
		},
	},
	modules: {
		invoice, device, setup,
	},
} );
