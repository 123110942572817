<template>
	<div class="editor" :class="{
		preview:$store.state.pdf.preview && !$store.getters['device/isSmallDevice'],
		vertical:$store.state.vertical || $store.getters['device/isLeanDevice'],
		lean: $store.getters['device/isLeanDevice'],
		small: $store.getters['device/isSmallDevice']
	}">
		<div v-if="!setupVisible" class="form data">
			<div class="head">
				<div class="fields">
					<TextField :value="invoiceId"
							   @input="track('setInvoiceId',$event)"
							   class="w50 number" prompt="Rechnungsnummer"
							   case="upper"></TextField>
					<TextField :value="customerId"
							   @input="track('setCustomerId',$event)"
							   class="w50 customer" prompt="Kundennummer"
							   case="upper"></TextField>
					<TextDate :value="billingDate"
							  @input="track('setBillingDate',$event)"
							  class="w50 billing" prompt="Rechnungsdatum"></TextDate>
					<TextDate :value="deliveryDate"
							  @input="track('setDeliveryDate',$event)"
							  class="w50 delivery" prompt="Lieferdatum"></TextDate>
					<TextBlock :value="recipient"
							   @input="track('setRecipient',$event)"
							   class="w100 address" prompt="Anschrift" :min-rows="5"
							   :max-rows="5"></TextBlock>
					<TextBlock :value="preface" @input="track('setPreface',$event)"
							   class="w100 preface" prompt="Einleitung"
							   :max-rows="5"></TextBlock>
					<TextBlock :value="postface"
							   @input="track('setPostface',$event)"
							   class="w100 postface" prompt="Grußformel"
							   :max-rows="5"></TextBlock>
				</div>
			</div>
			<div class="items">
				<ItemsEditor></ItemsEditor>
			</div>
		</div>
		<div v-if="setupVisible" class="form setup">
			<Setup></Setup>
		</div>
		<div class="result" v-if="$store.state.pdf.preview && !$store.getters['device/isSmallDevice']">
			<PdfPreview/>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import PdfPreview from "../components/PdfPreview";
import TextField from "../components/TextField";
import TextBlock from "../components/TextBlock";
import TextDate from "../components/TextDate";
import ItemsEditor from "../components/ItemsEditor";
import Renderer from "../renderer/invoice/index";
import Setup from "@/components/Setup";

export default {
	name: "Editor",
	components: {
		Setup,
		ItemsEditor,
		TextBlock,
		TextField,
		TextDate,
		PdfPreview
	},
	data() {
		return {
			$delay: null,
		};
	},
	computed: {
		...mapState( {
			invoiceId: state => state.invoice.invoiceId,
			customerId: state => state.invoice.customerId,
			billingDate: state => state.invoice.billingDate,
			deliveryDate: state => state.invoice.deliveryDate,
			preface: state => state.invoice.preface,
			postface: state => state.invoice.postface,
			recipient: state => state.invoice.recipient,
		} ),
		setupVisible() {
			return this.$store.state.setup.visible;
		},
	},
	methods: {
		track( action, value ) {
			this.$store.dispatch( `invoice/${action}`, value );
		},
	},
	created() {
		if ( !this.$store.getters["invoice/hasStorage"] ) {
			this.$store.dispatch( "invoice/useStorage", localStorage );
		}

		this.$store.dispatch( "renderPdf", { renderer: Renderer } );
	},
};
</script>

<style scoped lang="scss">
.editor {
	margin: 0;
	padding: 0;
	position: relative;
	flex: 1000 1 auto;
	display: flex;

	&:not(.vertical) {
		flex-flow: row nowrap;
	}

	&.vertical {
		flex-flow: column nowrap;

		&.preview .form {
			max-height: 50vh;
		}

		.form {
			overflow: auto;
		}
	}
}

.form {
	flex: 1 1 50%;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;

	> .head {
		flex: 1 0 7em;

		> .fields {
			display: grid;
			grid-template-areas:
					"address number customer"
					"address billing delivery"
					"preface postface postface";
			grid-template-columns: 2fr 1fr 1fr;
			grid-template-rows: auto 1fr auto;
			grid-gap: 0.5rem;
			padding: 1rem;
			position: relative;
			background: var(--header-background);

			> div {
				position: relative;
			}

			> .number {
				grid-area: number;
			}

			> .customer {
				grid-area: customer;
			}

			> .billing {
				grid-area: billing;
			}

			> .delivery {
				grid-area: delivery;
			}

			> .address {
				grid-area: address;
			}

			> .preface {
				grid-area: preface;
			}

			> .postface {
				grid-area: postface;
			}
		}
	}

	> .items {
		flex: 1000 0 auto;
		position: relative;
		box-sizing: border-box;
	}
}

.result {
	flex: 1 1 50%;
}

@media screen and (min-width: 1024px) {
	.form > .items {
		overflow: hidden;
	}
}

@media screen and (max-width: 1023.99px) and (min-width: 640px) {
	.form > .fields {
		grid-template-areas: "number customer" "billing delivery" "address address" "preface preface" "postface postface";
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 639.99px) {
	.form > .fields {
		grid-template-areas: "number" "customer" "billing" "delivery" "address" "preface" "postface";
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 1799.99px) {
	.editor:not(.vertical) {
		> .form {
			flex: 60 1 50%;
		}

		> .result {
			flex: 40 1 50%;
		}
	}
}
</style>

<style lang="scss">
.editor.vertical .form .head textarea {
	max-height: 4rem;
}
</style>
