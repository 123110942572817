<template>
	<button v-if="faIcon" class="toggle icon" :class="{set: current, dark}" @click="toggle"><fa :icon="faIcon"></fa></button>
	<button v-else class="toggle text" :class="{set: current, dark}" @click="toggle">{{ label }}</button>
</template>

<script>
export default {
	name: "ToggleButton",
	props: {
		state: Boolean,
		label: String,
		icon: String,
		dark: Boolean,
	},
	data() {
		return {
			current: this.state,
		};
	},
	methods: {
		toggle() {
			this.current = !this.current;
			this.$emit( "toggle", this.current );
		},
	},
	computed: {
		faIcon() {
			const match = /^(?:(fa[sdlbr])-)?(\S+)$/.exec( this.icon || "" );
			if ( match ) {
				return [ match[1] || "fad", match[2] ];
			}

			return null;
		},
	},
};
</script>

<style scoped lang="scss">
	.toggle {
		appearance: none;
		font: inherit;
		line-height: inherit;
		background: var(--bright-icon-background);
		color: var(--bright-icon-text);
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		&.dark {
			background: var(--dark-icon-background);
			color: var(--dark-icon-text);
		}

		&:not(:disabled) {
			cursor: pointer;

			&.set {
				background: var(--bright-icon-set-background);
				color: var(--bright-icon-set-text);

				&.dark {
					background: var(--dark-icon-set-background);
					color: var(--dark-icon-set-text);
				}
			}

			&:hover {
				background: var(--bright-icon-focused);

				&.set {
					background: var(--bright-icon-set-focused);
				}

				&.dark {
					background: var(--dark-icon-focused);

					&.set {
						background: var(--dark-icon-set-focused);
					}
				}
			}
		}

		&.icon {
			border: none;
			min-width: 2em;
		}

		&.text {
			padding-left: 0.7em;
			padding-right: 0.7em;
		}

		&:disabled {
			opacity: 0.3;
		}
	}

	@media screen and (max-width: 639.99px) {
		.action:disabled {
			display: none;
		}
	}
</style>
